// import logo from './logo.svg';
// import './App.css';
import Fullscreen from 'components/Fullscreen'
import React from 'react'
import Heading from './Heading'
import Button from './Button'
import { SpinnerCircularFixed } from 'spinners-react'
function LoadingPane({ playing, loaded, onClick }) {
  if (playing) return null

  return (
    <Fullscreen
      zIndex="2"
      // background="rgba(200,255,200,1)"
      //   background="white linear-gradient(180deg, rgba(236, 119, 0, 0) 29.47%, rgba(236, 119, 0, 0.51) 88.41%)"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <Heading>
        Onze Robijn beer wilt graag met je praten, unmute je geluid tijdens het
        wachten
      </Heading>
      {loaded ? (
        <Button
          onClick={onClick}
          data-category="engagement"
          data-user-action="play AR"
        >
          Start{' '}
        </Button>
      ) : (
        <Button disabled>
          Aan het laden...{' '}
          <SpinnerCircularFixed
            size={16}
            thickness={200}
            color="#000"
            secondaryColor="#666"
          />
        </Button>
      )}
    </Fullscreen>
  )
}

export default LoadingPane
