// import logo from './logo.svg';
// import './App.css';
import CameraViewWithPermissions from 'components/CameraViewWithPermissions'
import Fullscreen from 'components/Fullscreen'
import Scene from 'components/Scene'
import { usePage, useTrack } from 'components/tracking/TrackingProvider'
import FlowerPane from 'components/ui/FlowerPane'
import Flowers from 'components/ui/Flowers'
import LoadingPane from 'components/ui/LoadingPane'
// import audio from 'resources/studio/voiceover.mp3'
import Logo from 'components/ui/Logo'
import Video from 'components/ui/Video'
import React from 'react'
import usePageVisibility from 'utils/usePageVisibilty'
// import OneTrustScript from 'components/tracking/OneTrustScript'
function App() {
  // const [interacted, setInteracted] = React.useState(false)

  const [playing, setPlaying] = React.useState(false)
  const audioRef = React.useRef(null)

  const [bearLoaded, setBearLoaded] = React.useState(null)
  const [audioLoaded, setAudioLoaded] = React.useState(null)
  const [cameraReady, setCameraReady] = React.useState(null)

  const isVisible = usePageVisibility()

  const handleClick = async () => {
    try {
      await audioRef.current.play()
      setPlaying(true)
    } catch (e) {
      console.log(e)
    }
  }

  const page = usePage()
  const track = useTrack()

  React.useEffect(() => {
    page(document.title, {})
  }, [])

  React.useEffect(() => {
    if (
      window.location.search.includes('autoplay') &&
      bearLoaded &&
      audioLoaded &&
      cameraReady
    )
      handleClick()
  }, [bearLoaded, audioLoaded, cameraReady])

  const loaded = bearLoaded && audioLoaded

  const [videoPlaying, setVideoPlaying] = React.useState(false)
  React.useEffect(() => {
    if (playing) {
      const timeout = setTimeout(() => setVideoPlaying(true), 8000)
      return () => clearTimeout(timeout)
    }
  }, [playing])

  const handleAudioEnded = e => {
    console.log('ended')
    try {
      audioRef.current.src = '/end-loop.mp3'
      audioRef.current.loop = true
      audioRef.current.play()
    } catch (e) {
      console.error(e)
    }
  }

  const [clickHotSpot, setClickHotspot] = React.useState(false)

  return (
    <>
      {/* <OneTrustScript id="82286a43-a895-4088-9f38-dd3690240064" /> */}
      <Logo />

      {cameraReady && <Flowers />}

      <CameraViewWithPermissions
        onPlay={() => {
          track('click', { label: 'camerastarted', category: 'engagement' })
          setCameraReady(true)
        }}
        onError={() => {
          track('click', { label: 'camerafailed', category: 'engagement' })
          setCameraReady(true)
        }}
      >
        {props => <FlowerPane {...props} />}
      </CameraViewWithPermissions>

      <LoadingPane playing={playing} loaded={loaded} onClick={handleClick} />

      <audio
        src="/voiceover.mp3"
        ref={audioRef}
        onCanPlay={() => setAudioLoaded(true)}
        onLoadedMetadata={() => setAudioLoaded(true)}
        onEnded={handleAudioEnded}
        muted={!isVisible}
      />
      <Scene playing={playing} onLoaded={() => setBearLoaded(true)} />

      <Video
        active={videoPlaying}
        onEnded={() => {
          track('video', {
            category: 'engagement',
            userAction: 'complete',
          })
          setClickHotspot(true)
          setVideoPlaying(false)
        }}
      />

      {clickHotSpot && (
        <Fullscreen style={{ display: 'flex' }}>
          <a
            href="https://www.cleanipedia.com/nl/drywash.html?utm_source=Leaflet&utm_medium=QR&utm_campaign=Robijn-Dry-Wash-BH0420-NL&utm_content=ProductExperience-POS&utm_term=&evt_product_id=VyxqMCA9QynH8yU8KPVPmdwr&evt_implicit_scan_id=VTD5KmSn4HX6radBD7NhhKea"
            target="_blank"
            rel="noreferrer"
            data-user-action="click external"
            // data-category="engagement"
            style={{
              display: 'block',
              flexGrow: 1,
              background: 'rgba(255, 0 0 0.4)',
            }}
          >
            &nbsp;
          </a>
        </Fullscreen>
      )}
    </>
  )

  // return interacted ? (
  //   <Scene />
  // ) : (
  //   <button onClick={() => setInteracted(true)}>Do interaction</button>
  // )
}

export default App
