import { useTrack } from './TrackingProvider'

const removeEmpty = o =>
  Object.fromEntries(Object.entries(o).filter(([_, v]) => v ?? false))

const trackedTags = ['A', 'BUTTON', '[data-track]']

const useTrackClickHandler = tracking => {
  const track = useTrack(tracking)

  const handleClick = e => {
    if (e.target.dataset.tracked) return
    if (e.target.closest('[data-tracked]')) return // handled by a manual ting

    const target =
      trackedTags.some(tag => tag === e.target.tagName) ||
      e.target.dataset.track
        ? e.target
        : e.target.closest(trackedTags.join())

    if (!target) return

    const { dataset, alt, innerText, href } = target

    const label =
      dataset.track && dataset.track !== 'true'
        ? dataset.track
        : innerText || alt

    const section = e.target.closest('[data-track-section')?.dataset
      .trackSection

    track(
      'click',
      removeEmpty({
        ...dataset,
        track: null, // remove from dataset
        trackSection: null, // remove from dataset
        category: 'engagement',
        label,
        href,
        section,
      })
    )
  }

  return handleClick
}

export default useTrackClickHandler
