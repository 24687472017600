// import { useRouter } from 'next/router'
import React from 'react'
import { useTrack } from './TrackingProvider'

const seconds = 5

const useTrackTime = () => {
  // const router = useRouter()
  const track = useTrack()
  React.useEffect(() => {
    let i = 1
    const id = setInterval(() => {
      if (document.hidden) return
      track('page time', {
        total: i * seconds,
        interval: i * seconds === 5 ? 2 : 5,
        nonInteraction: 1,
      })
      i++
    }, seconds * 1000)
    const one = setTimeout(
      () =>
        !document.hidden &&
        track('page time', {
          total: 1,
          interval: 1,
          nonInteraction: 1,
        }),
      1000
    )
    const three = setTimeout(
      () =>
        !document.hidden &&
        track('page time', {
          total: 3,
          interval: 2,
          nonInteraction: 1,
        }),
      3000
    )
    return () => {
      clearInterval(id)
      ;[one, three].forEach(id => clearTimeout(id))
    }
  }, [])
}

export default useTrackTime
