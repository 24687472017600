const styles = {
  position: 'fixed',
  right: '0',
  bottom: '0',
  width: '100%',
  height: '100%',
  // margin: '0 auto',
  marginLeft: 'auto',
  marginRight: 'auto',
  overflow: 'hidden',
  // position: 'absolute',
  // maxWidth: '60vh',
}

const innerStyles = { position: 'relative', maxWidth: '60vh', margin: '0 auto' }

const Fullscreen = ({
  children,
  style = {},
  zIndex = 1,
  background = 'transparent',
  ...rest
}) => (
  <div style={{ background, zIndex, ...styles, ...style }} {...rest}>
    {/* <div style={innerStyles}> */}
    {children}
    {/* </div> */}
  </div>
)

export default Fullscreen
