import React from 'react'
// import Head from 'next/head'
// import getDistance from 'geolib/es/getDistance'

// const AdobeTaggingStateContext = React.createContext()
const AdobeTagsDispatchContext = React.createContext()

function AdobeTagsReducer(state, action) {
  Object.keys(action).forEach(key => {
    switch (key) {
      case 'destinationURL':
        window.digitalData.page.pageInfo.destinationURL = action[key]
        break
      case 'pageName':
        window.digitalData.page.pageInfo.pageName = action[key]
        break
      case 'pageType':
        window.digitalData.page.category.pageType = action[key]
        break
      case 'contentType':
        window.digitalData.page.attributes.contentType = action[key]
        break
      case 'articleName':
        window.digitalData.page.attributes.articleName = action[key]
        break
    }
  })
  return state
}

function AdobeTagsProvider({
  children,
  internalDomain,
  language,
  brandCategory,
  sitetype,
  country,
  globalBrand,
  localBrand,
  // below for  trackingInfo.tool:
  trackingId,
  reportSuiteId,
  scriptSrc,
}) {
  const [state, dispatch] = React.useReducer(AdobeTagsReducer, null)
  const digitalData = {
    siteInfo: {
      channel: 'Mobile Site',
      sitetype,
      internalDomain, // : 'INTERNAL DOMAIN',
    },
    page: {
      pageInfo: {
        destinationURL: '', // 'DESTINATION URL',
        pageName: '', // 'PAGENAME',
        language, // : 'PAGE LANGUAGE',
      },
      category: {
        pageType: '', // 'PAGE TYPE-To be set on each page change',
        primaryCategory: 'Brand Site',
        subCategory1: 'SITE SECTION 1',
        subCategory2: 'SITE SECTION 2',
        subCategory3: 'SITE SECTION 3',
      },
      attributes: {
        contentType: '', // 'CONTENT TYPE OF PAGE',
        articleName: '', // 'ARTICLE NAME',
        brandCategory, // : 'BRAND CATEGORY',
        country, // : 'BRAND COUNTRY',
        globalBrand, // : 'GLOBAL BRAND NAME',
        localBrand, // : 'LOCAL BRAND NAME',
      },
    },
    video: [],
    campaign: [],
    product: [],
    privacy: {
      accessCategories: [
        {
          domains: [],
        },
      ],
    },
    component: [],
    trackingInfo: {
      tool: [
        {
          id: trackingId,
        },
        {
          id: reportSuiteId,
        },
      ],
    },
    promotion: [],
    event: [],
  }

  if (typeof window !== 'undefined' && !window.digitalData)
    window.digitalData = digitalData

  React.useEffect(() => {
    if (!scriptSrc) return

    const script = document.createElement('script')
    script.src = 'https:' + scriptSrc

    script.async = true
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [scriptSrc])

  return (
    <>
      {/* <Head> */}
      <script
        dangerouslySetInnerHTML={{
          __html: `(function(g,b,d,f){(function(a,c,d){if(a){var e=b.createElement("style");e.id=c;e.innerHTML=d;a.appendChild(e)}})(b.getElementsByTagName("head")[0],"at-body-style",d);setTimeout(function(){var a=b.getElementsByTagName("head")[0];if(a){var c=b.getElementById("at-body-style");c&&a.removeChild(c)}},f)})(window,document,"body {opacity: 0 !important}",3E3);`,
        }}
      />
      {/* <script src="/lib/adobe-tagging.js" /> */}
      {/* <script
          src="https://assets.adobedtm.com/e6bd1902389a/064a636dbf94/launch-ea234d601bc9.min.js"
          async
        /> */}
      {/* </Head> */}
      {/* <AdobeTaggingStateContext.Provider value={state}> */}
      <AdobeTagsDispatchContext.Provider value={dispatch}>
        {children}
      </AdobeTagsDispatchContext.Provider>
      {/* </AdobeTaggingStateContext.Provider> */}
    </>
  )
}

// function useAdobeTaggingState() {
//   const context = React.useContext(AdobeTaggingStateContext)
//   if (context === undefined) {
//     throw new Error(
//       'useAdobeTaggingState must be used within a AdobeTaggingProvider'
//     )
//   }
//   return context
// }

function useAdobeTagsDispatch() {
  const context = React.useContext(AdobeTagsDispatchContext)
  if (context === undefined) {
    throw new Error(
      'useAdobeTaggingDispatch must be used within a AdobeTaggingProvider'
    )
  }
  return context
}

function AdobeTags(tags) {
  const setAdobeTags = useAdobeTagsDispatch()
  React.useEffect(() => void setAdobeTags(tags), [])
  return null
}

function useAdobeTags(tags) {
  const setAdobeTags = useAdobeTagsDispatch()
  React.useEffect(() => void setAdobeTags(tags), [tags])
  return null
}

export {
  AdobeTagsProvider,
  // useAdobeTaggingState,
  useAdobeTagsDispatch,
  AdobeTags,
  useAdobeTags,
}
