const styles = {
  position: 'fixed',
  top: 0,
  left: 16,
  width: 94,
  height: 40,
  zIndex: 10,
}

export default function Logo() {
  return <img style={styles} src="/logo-robijn.svg" />
}
