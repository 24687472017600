// import Head from 'next/head'

import { css, jsx } from '@emotion/react'
import React from 'react'
// import CameraViewErrors from 'components/CameraViewErrors'
// import * as analytics from 'utils/analytics'

const constraints = {
  audio: false,
  video: { facingMode: 'environment' },
}

const styles = {}
styles.video = css`
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  transform: translateX(calc((100% - 100vw) / 2));
  z-index: 1;
`
const fullScreenStyle = {
  position: 'fixed',
  right: '0',
  bottom: '0',
  minWidth: '100%',
  minHeight: '100%',
  transform: 'translateX(calc((100% - 100vw) / 2))',
  zIndex: '1',
}

export default function CameraView({
  active,
  reverse,
  onError,
  onSkip,
  fallback = null,
  ...rest
}) {
  const ref = React.useRef()

  const [error, setError] = React.useState(null)

  React.useEffect(async () => {
    const video = ref?.current
    if (video)
      try {
        const stream = await navigator.mediaDevices.getUserMedia(constraints)
        video.srcObject = stream
        video.play()
        setError(null)
        // analytics.track('click', {
        //   category: 'ar',
        //   label: 'camera permission',
        //   userAction: 'allow',
        // })
      } catch (error) {
        console.error(error)
        // analytics.track('click', {
        //   category: 'ar',
        //   label: 'camera permission',
        //   userAction: 'cancel',
        // })

        typeof onError === 'function' && onError(error)
        setError(error)
      }
  }, [ref])

  if (error) {
    console.log(error)
    return <div style={{ ...fullScreenStyle, background: '#eea' }}></div>
  }

  return (
    <>
      {/* <Head>
        <meta
          key="mobile-web-app-capable"
          name="mobile-web-app-capable"
          content="yes"
        />
      </Head> */}
      <video
        // css={styles.video}
        style={fullScreenStyle}
        ref={ref}
        playsInline
        autoPlay
        muted
        onError={e => console.log(e)}
        {...rest}
      />
      {/* <CameraViewErrors error={error} onSkip={onSkip} /> */}
    </>
  )
}

// import './CameraView.css'
// import Camera from './cmra'

// function startStream(video) {
//   // Get access to the camera!
//   const getUserMedia =
//     navigator.mediaDevices?.getUserMedia
//     navigator.getUserMedia
//     navigator.webkitGetUserMedia
//     navigator.mozGetUserMedia

//   if (typeof getUserMedia !== 'function') return alert('NO')

//   const success = function (stream) {
//     video.srcObject = stream
//     video.play()
//   }

//   getUserMedia({ video: { facingMode: 'environment' } }).then(success)

//   // video.onloadedmetadata = function () {
//   //   if (stream.active) video.play()
//   //   else getUserMedia()
//   // }
// }

// function startStream(video) {
//   navigator.mediaDevices
//     .getUserMedia(constraints)
//     .then(stream => (video.srcObject = stream))
//     .catch(e => console.log(e))
// }

/* <style jsx>
        {`
          video {
            position: fixed;
            right: 0;
            bottom: 0;
            min-width: 100%;
            min-height: 100%;
            transform: translateX(calc((100% - 100vw) / 2));
          }
        `}
      </style> */

/* <script
          key="web-rtc-adapter"
          async
          dangerouslySetInnerHTML={{
            __html: `
if(navigator.userAgent.match(/(iPhone|iPod|iPad)/i)){
  const script = document.createElement('script')
  script.src = "/web-rtc-adapter.js"
  document.body.appendChild(script)
}`,
          }}
        /> */

/* <script
          src="https://webrtc.github.io/adapter/adapter-latest.js"
          type="text/javascript"
        /> */
