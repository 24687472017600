export default async function getGeoData({
  key = '100f9c0d181d4f58a649df07daea3784',
} = {}) {
  // const GEO_KEY = process.env.NEXT_PUBLIC_GEO_KEY
  const url = `https://api.bigdatacloud.net/data/ip-geolocation?key=${key}`
  const options = { headers: { 'Content-Type': 'application/json' } }

  const response = await fetch(url, options)
  const json = await response.json()

  const getValuesFromKey = (k, o) =>
    k.reduce(
      (p, c) => (o.hasOwnProperty(c) ? Object.assign(p, { [c]: o[c] }) : p),
      {}
    )

  const payload = {
    language: navigator.language,
    // userAgent: navigator.userAgent,
    // screen: { width: screen.width, height: screen.height },
    country: getValuesFromKey(
      ['isoAlpha2', 'name', 'unRegion'],
      json.country || {}
    ),
    location: getValuesFromKey(
      [
        'continent',
        'continentCode',
        'city',
        'localityName',
        // 'latitude',
        // 'longitude',
        'timeZone',
        'isoPrincipalSubdivision',
      ],
      json.location || {}
    ),
  }
  return payload
}
