import React from 'react'
import { useTrack } from './TrackingProvider'

const useTrackOnce = tracking => {
  const track = useTrack(tracking)
  const ref = React.useRef(false)
  return React.useCallback(
    (action = 'click', tracking) => {
      if (tracking === false) ref.current = false
      else if (ref?.current === true) return console.log('already tracked once')
      else {
        ref.current = true
        track(action, tracking)
      }
    },
    [track, ref]
  )
}

export default useTrackOnce
