import { useFrame, useLoader } from '@react-three/fiber'
import useTrackOnce from 'components/tracking/useTrackOnce'
import React, { useEffect, useState } from 'react'
import bear from 'resources/studio/bear-1k.glb'
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
// const GLTFLoader = require('three/examples/jsm/loaders/GLTFLoader').GLTFLoader

function Basic({ playing, onLoaded, onProgress, ...rest }) {
  const model = useLoader(GLTFLoader, bear, ({ manager }) => {
    // console.log(loader)
    if (typeof onProgress === 'function') manager.onProgress = onProgress
    // can only get the following:
    // (item, loaded, total) => {}
  })
  // rest of code paused until loaded

  const { scene, geometries, center, animations } = model
  const [mixer] = useState(() => new THREE.AnimationMixer(scene))

  useEffect(() => {
    typeof onLoaded === 'function' && onLoaded(true)
  }, [])

  useEffect(() => {
    // console.log(animations)
    if (playing) {
      mixer.setTime(0)
      animations.forEach(clip => {
        // mixer.clipAction(clip).reset()
        mixer.clipAction(clip).play()
      })
    }
    // mixer.addEventListener('loop', function (e) {
    //   mixer.setTime(32)
    // })
  }, [animations, scene, playing])

  const trackCompleteOnce = useTrackOnce({
    action: 'ar complete',
    nonInteration: 1,
  })
  useFrame((state, delta) => {
    // if (mixer.time === 36.66666793823242) mixer.setTime(32)
    if (mixer.time > 36.6) {
      mixer.setTime(31.6)
      trackCompleteOnce()
    } else mixer.update(delta)
  })

  // useEffect(() => {
  //   mixer.setTime(27)
  // })

  return <primitive {...rest} object={scene} />
}

export default Basic
