const pinkStyles = {
  position: 'fixed',
  left: -20,
  bottom: -30,
  width: 180,
  zIndex: 6,
}

const orangeStyles = {
  position: 'fixed',
  right: -20,
  bottom: -90,
  width: 160,
  zIndex: 6,
}

export default function Flowers({ active }) {
  return (
    <>
      <img src="flowers-orange.png" style={orangeStyles} alt="Orange flowers" />
      <img src="flowers-pink.png" style={pinkStyles} alt="Pink flowers" />
    </>
  )
}
