const styles = {
  textShadow:
    '-1.5px -1.5px 0 #EC7700, 1.5px -1.5px 0 #EC7700, -1.5px 1.5px 0 #EC7700,1.5px 1.5px 0 #EC7700',
  color: '#fff',
  textTransform: 'uppercase',
  paddingLeft: 24,
  paddingRight: 24,
}

export default function Heading({ children, ...props }) {
  return <h3 style={styles}>{children}</h3>
}
