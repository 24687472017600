// import { v4 as uuid } from 'uuid'

// const getSessionId = () => {
//   if (typeof window == 'undefined') return ''
//   if (!sessionStorage) return 'unsupported'
//   const storedSessionId = sessionStorage?.getItem('sessionId')
//   if (storedSessionId) return storedSessionId

//   const sessionId = uuid()
//   sessionStorage.setItem(sessionId, 'sessionId')
//   return sessionId
// }

// const sessionId = getSessionId()

export const track = (action, tracking = {}) => {
  if (typeof window?.analytics?.track === 'function') {
    action !== 'page time' && console.log('TRACK', action, tracking)
    window.analytics.track(action, tracking)
  } else console.warn('analytics.track is not yet a function')
}

export const page = (title, tracking = {}) => {
  if (typeof window?.analytics?.page === 'function') {
    console.log('PAGE', title, tracking)
    window.analytics.page(title, tracking)
  } else console.warn('analytics.page is not yet a function')
}

export const identify = data => {
  if (typeof window?.analytics?.identify === 'function') {
    // console.log('IDENTIFY', data)
    window.analytics.identify(data)
  } else console.warn('analytics.identify is not yet a function')
}

export const getUserId = () => {
  if (typeof window !== 'undefined')
    if (typeof window.analytics !== 'undefined')
      if (typeof window.analytics.user === 'function')
        return (
          window?.analytics?.user().anonymousId() ||
          console.log('no analytics user function present')
        )
}

// const tracking = {
//   context: {
//     campaign: {
//       name: '',
//       source: '',
//       medium: '',
//       term: '',
//       content: '',
//     }
//   }
// }

// export const getUser = window.analytics.ready(function () {
//   var user = analytics.user()
//   var id = user.id()
//   var traits = user.traits()
//   console.log(user)
// })

// import React from 'react'

// export const track = (action, tracking = {}) => {
//   const options = { ...tracking, context: { campaign: window.naughtyCampaign } }
//   console.log('TRACK', action, options)
//   window.analytics.track(action, options)
// }

// export const page = (title, tracking = {}) => {
//   const options = { ...tracking, context: { campaign: window.naughtyCampaign } }

//   console.log('PAGE', title, options)
//   window.analytics.page(title, options)
// }
// // TODO: not like this
// if (window) {
//   console.log('WINDOW', window.location.search)
//   function getQueryVariable(variable) {
//     var query = window.location.search.substring(1)
//     var vars = query.split('&')
//     for (var i = 0; i < vars.length; i++) {
//       var pair = vars[i].split('=')
//       if (pair[0] == variable) {
//         return pair[1]
//       }
//     }
//     return void 0
//   }

//   var campaign = {
//     name: getQueryVariable('utm_campaign'),
//     source: getQueryVariable('utm_source'),
//     medium: getQueryVariable('utm_medium'),
//     term: getQueryVariable('utm_term'),
//     content: getQueryVariable('utm_content'),
//   }
//   window.naughtyCampaign = campaign
// }
