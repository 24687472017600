import React from 'react'
import useTrackClickHandler from './useTrackClickHandler'

const useTrackClickGlobal = tracking => {
  const handleClick = useTrackClickHandler(tracking)
  React.useEffect(() => {
    // const el = ref?.current || document
    document.addEventListener('click', handleClick)

    return () => document.removeEventListener('click', handleClick)
  }, [])
}

export default useTrackClickGlobal
