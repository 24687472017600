import React from 'react'
// import { TrackedLink as Link } from './TrackPassive'
import { track, page } from './analytics'

const TrackingContext = React.createContext()

// const TrackedLink = React.forwardRef(({ tracking = {}, ...rest }, ref) => {
//   const trackingContext = useTracking()
//   return (
//     <Link ref={ref} {...rest} tracking={{ ...tracking, ...trackingContext }} />
//   )
// })

function TrackingProvider({ children, tracking }) {
  const existingTracking = useTracking()
  return (
    <TrackingContext.Provider value={{ ...existingTracking, ...tracking }}>
      {children}
    </TrackingContext.Provider>
  )
}

function useTracking() {
  const context = React.useContext(TrackingContext) || {}
  return context
}

function useTrack(componentTracking = {}) {
  const contextTracking = React.useContext(TrackingContext) || {}
  return (action = 'click', instanceTracking = {}) =>
    track(action, {
      ...contextTracking,
      ...componentTracking,
      ...instanceTracking,
    })
}

// export function useTrackOnce(props) {
//   const track = useTrack(props)
//   const tracked = React.useRef(false)
//   return tracked.current === false ? t => track(t) : () => {}
// }
// export function useTrackOnce(key) {
//   const track = useTrack()
//   const tracked = React.useRef([])
//   return tracked.current.includes(key) ? () => {} : t => track(t)
// }

function usePage() {
  return (...args) => page(...args)
}

export { TrackingProvider, useTracking, useTrack, usePage }
