import useTrackClickGlobal from './useTrackClickGlobal'
import useTrackGeo from './useTrackGeo'
// import useTrackPage from './useTrackPage'
import useTrackTime from './useTrackTime'

const useTrackPassive = () => {
  useTrackTime()
  useTrackGeo()
  // useTrackPage()
  useTrackClickGlobal()
}

export default useTrackPassive
